// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://rp6oj5trovhprm4r3piuyyavdq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-h3m7m5vfqbb6vh3cx2x43fuxpq",
    "aws_content_delivery_bucket": "resume-20190816145923-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://resume-20190816145923-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;



